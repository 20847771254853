import { createRouter, createWebHistory } from "vue-router";

import Home from "@/pages/Home";
// import Pricing from "@/pages/Pricing";
import NotFound from "@/pages/404";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/pricing/",
  //   name: "Pricing",
  //   component: Pricing,
  // },
  {
    path: "/:pathMath(.*)*",
    name: "404",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
