<template>
  <div class="ease">
    <kript-container>
      <div class="grid">
        <div class="header">
          <span class="pill">EASY TO USE</span>
          <h2>Build your Crypto Payments Solution</h2>
        </div>

        <p>
          We are dedicated to helping you build, launch, and manage your very
          own cryptocurrency payment solution. Kript provides a convenient API,
          with all the tools you need at hand - just configure, install and you
          are good to go.
        </p>
      </div>
    </kript-container>
  </div>
</template>

<script>
export default {
  name: "HomeEase",
};
</script>

<style lang="scss" scoped>
.ease {
  padding-top: 123px;
  padding-bottom: 72px;

  @media (min-width: 768px) {
    padding: 141px 0;
    background: {
      image: url("~@/assets/images/backgrounds/ease.png");
      size: cover;
    }
  }

  .pill {
    margin-bottom: 13px;
  }

  .grid {
    display: grid;
    grid-gap: 17px;
    align-items: center;

    h2 {
      font-size: 28px;
    }

    @media (min-width: 768px) {
      grid-template-columns: 45% 1fr;
      grid-gap: 102px;

      h2 {
        font-size: 48px;
      }
    }
  }
}
</style>
