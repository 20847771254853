import { createApp } from "vue";
import App from "./App.vue";
import Container from "./components/Container";
import "@/assets/style/main.scss";
import router from './router'

const app = createApp(App).use(router);

app.component("KriptContainer", Container);
app.mount("#app");
