<script>
export default {
  name: '404',
};
</script>

<script setup>
import NotFound from '@/assets/images/NotFound.svg'

</script>

<template>
  <div id="not-found">
    <nav>
      <router-link to="/">
        <img class="logo" alt="Kript logo" src="@/assets/logo.png"/>
      </router-link>
    </nav>
    <div class="body">
      <img class="navbar-brand" alt="Kript logo" src="@/assets/images/NotFound.svg"/>
      <div>
        <h1>Oops<span>!</span></h1>
        <p>It seems this page is lost in space. Best return home to safety</p>
        <button class="navbar-button btn" type="submit">
          <router-link to="/">
            Go back
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div#not-found {
  height: 100vh;
  max-width: 1300px;
  margin: auto;
  padding: 2rem 4rem;

  .logo {
    height: 30px;
  }

  svg {
    &.live {
      height: 28px;
      width: 72px;
    }

    &.sandbox {
      height: 32px;
      width: 185px;
    }
  }

  div.body {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;

    div {
      padding: 1rem 2rem;

      h1,
      p {
        color: #363636;
      }

      h1 {
        font-size: 3.75rem;
        font-weight: 700;

        span {
          color: #4949e0;
        }
      }

      p {
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 1.8rem;
        margin: 1.5rem 0;
      }

      button {
        max-width: 250px;
      }
    }
  }
}

</style>
