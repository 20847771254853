<template>
  <nav class="navbar" :class="{ scrolled }">
    <kript-container>
      <img class="navbar-brand" alt="Kript logo" src="@/assets/logo.png"/>

      <!-- <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a href="https://docs.kriptup.io/">
            API Documentation
          </a>
        </li>
<!--        <li class="nav-item">-->
<!--          <router-link to='/pricing/'>-->
<!--            Pricing-->
<!--          </router-link>-->
<!--        </li>-->
        <li class="nav-item">
          <a href="https://vault.kriptup.io/sign-in" target="_blank" rel="noreferrer noopener">
            Log In
          </a>
        </li>
      </ul>
      <button class="navbar-button btn" type="submit">
        <a href="https://vault.kriptup.io/sign-up" target="_blank" rel="noreferrer noopener">
          Get Started
        </a>
      </button>
    </kript-container>
  </nav>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "NavBar",
  setup() {
    const scrolled = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        scrolled.value = document.scrollingElement.scrollTop > 47;
      });
    });

    return {scrolled};
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 1rem 0;
  font-size: 14px;
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;

  &.scrolled {
    box-shadow: 0 2px 2px #00000029;
    background: {
      image: url("~@/assets/images/backgrounds/white.jpg");
      size: 100vw 100vh;
      position: left top;
    }
  }

  .container {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }

  &-brand {
    height: 33px;
  }

  &-nav {
    display: flex;
    gap: 45px;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;

    .nav-item {
      display: none;

      &:last-of-type {
        display: inline;
      }

      a {
        cursor: pointer;
      }

      @media (min-width: 768px) {
        display: inline;

        a {
          all: unset;
          cursor: pointer;
        }
      }
    }

    @media (min-width: 768px) {
      margin-left: unset;
      margin-right: unset;
    }
  }
}
</style>
