<script>
export default {
  name: 'Home'
}
</script>

<script setup>
import SiteNavbar from "@/partials/Navbar";
import HomeHeader from "@/fragments/Header";
import HomeBody from "@/fragments/Body";
import HomeCoins from "@/fragments/Coins";
import HomeEase from "@/fragments/Ease";
import HomeBuild from "@/fragments/Build";
import SiteFooter from "@/partials/Footer";
</script>

<template>
  <site-navbar/>
  <home-header/>
  <home-body/>
  <home-coins/>
  <home-ease/>
  <home-build/>
  <site-footer/>
</template>
