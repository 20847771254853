<template>
  <footer class="footer">
    <kript-container>
      <ul class="footer-links">
        <li class="footer-link">
          <a href="https://docs.kriptup.io/">API Documentation</a>
        </li>
        <!--        <li class="footer-link">Pricing</li>-->
        <!--        <ul class="social-media">-->
        <!--          <li class="social-link">-->
        <!--            <img src="@/assets/images/twitter.svg" alt="Twitter"/>-->
        <!--          </li>-->
        <!--          <li class="social-link">-->
        <!--            <img src="@/assets/images/discord.svg" alt="Discord"/>-->
        <!--          </li>-->
        <!--        </ul>-->
      </ul>
      <div class="footer-terms">
        <p>© Copyright {{ new Date().getFullYear() }} Kript</p>
        <a href="https://iflux.app/terms" target="_blank" rel="nofollow noopener"> Terms of Service </a>
      </div>
    </kript-container>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding-block: 49px;

  &-links {
    font-size: 20px;
    font-weight: bold;
  }

  &-link {
    list-style-type: disc;
    margin-left: 1em;
    margin-right: 72px;
  }

  &-terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 42px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .social-media {
    margin-top: 35px;
    display: flex;
    align-items: center;

    img:first-of-type {
      margin-right: 20px;
    }
  }

  @media (min-width: 768px) {
    padding-block: 38px;
    position: relative;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background: {
        image: url(~@/assets/images/backgrounds/purple.jpg);
        size: 100% 100%;
      }
      z-index: -1;
    }

    &-links {
      display: flex;
      align-items: center;

      a {
        all: unset;
        cursor: pointer;
      }
    }

    .social-media {
      margin-top: 0;
      margin-left: auto;
    }

    &-terms {
      margin-top: 60px;
    }
  }
}
</style>
