<template>
  <kript-container>
    <span class="pill">Multi Chain Support</span>
    <div class="text">
      <h2>Build with support for many cryptocurrencies</h2>
      <p>
        Enterprise-ready, modular blockchain service that simplifies integration
        to build on various blockchains networks. Let’s be your gateway to
        multiple cryptocurrency networks.
      </p>
    </div>
  </kript-container>

  <kript-container class="coin">
    <ul class="coin-list">
      <li v-for="coin in coins" :key="coin.title" class="coin-item">
        <div
          class="coin-image"
          :style="{ backgroundImage: `url(${coin.background})` }"
        >
          <img :src="coin.icon" :alt="coin.title" />
        </div>
        <div class="coin-title">{{ coin.title }}</div>
      </li>
    </ul>

    <button class="coin-button" @click="showModal = true">
      View full crypto support list
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.926"
        height="9.926"
        viewBox="0 0 9.926 9.926"
      >
        <g
          id="Icon_feather-arrow-up-right"
          data-name="Icon feather-arrow-up-right"
          transform="translate(-9.086 -9.086)"
        >
          <path
            id="Path_236"
            data-name="Path 236"
            d="M10.5,17.6l7.1-7.1"
            fill="none"
            stroke="#4949e0"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_237"
            data-name="Path 237"
            d="M10.5,10.5h7.1v7.1"
            fill="none"
            stroke="#4949e0"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </button>
  </kript-container>

  <modal
    :show="showModal"
    dismissible
    backrop
    animation="scale"
    title="Supported Blockchains"
    @close="showModal = false"
  >
    <ul class="supported-chains">
      <li v-for="chain in blockchains" :key="chain.title">
        <img :src="chain.icon" />
        {{ chain.title }}
      </li>
    </ul>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { ref } from "vue";
export default {
  name: "HomeCoins",
  components: {
    Modal,
  },
  setup() {
    const coins = [
      {
        background: require("@/assets/images/backgrounds/white.jpg"),
        title: "Bitcoin",
        icon: require("@/assets/images/coins/bitcoin.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/peach.jpg"),
        title: "Ethereum",
        icon: require("@/assets/images/coins/ethereum.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/white.jpg"),
        title: "Solana",
        icon: require("@/assets/images/coins/solana.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/orange.jpg"),
        title: "Binance Coin",
        icon: require("@/assets/images/coins/binance.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/blue.jpg"),
        title: "Polygon MATIC",
        icon: require("@/assets/images/coins/polygon.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/purple.jpg"),
        title: "Tron",
        icon: require("@/assets/images/coins/tron.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/green.jpg"),
        title: "Ripple",
        icon: require("@/assets/images/coins/ripple.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/green.jpg"),
        title: "More",
        icon: require("@/assets/images/coins/more.svg"),
      },
    ];
    const blockchains = [
      {
        title: "Bitcoin",
        icon: require("@/assets/images/coins/bitcoin.svg"),
      },
      {
        title: "Ethereum",
        icon: require("@/assets/images/coins/ethereum.svg"),
      },
      {
        title: "Solana",
        icon: require("@/assets/images/coins/solana.svg"),
      },
      {
        title: "Binance Smart Chain",
        icon: require("@/assets/images/coins/binance.svg"),
      },
      {
        title: "Polygon",
        icon: require("@/assets/images/coins/polygon.svg"),
      },
      {
        background: require("@/assets/images/backgrounds/purple.jpg"),
        title: "Tron",
        icon: require("@/assets/images/coins/tron.svg"),
      },
      {
        title: "Ripple",
        icon: require("@/assets/images/coins/ripple.svg"),
      },
      {
        title: "Stellar",
        icon: require("@/assets/images/coins/stellar.svg"),
      },
    ];
    const showModal = ref(false);
    return { coins, showModal, blockchains };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .text {
    max-width: 693px;
  }

  h2 {
    font-size: 28px;
    font-weight: normal;
    margin-top: 12px;
  }

  p {
    margin-top: 17px;
  }

  @media (min-width: 768px) {
    margin-top: 135px;
    text-align: center;
    align-items: center;

    h2 {
      font-size: 48px;
    }

    p {
      margin-top: 24px;
    }
  }
}

.coin {
  overflow: hidden;
  padding-right: 0 !important;
  margin-top: 32px;
  margin-bottom: 69px;

  &-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }

  &-item {
    min-width: 285px;
    max-width: 285px;
    margin-right: 20px;
  }

  &-image {
    height: 166px;
    display: grid;
    place-items: center;
    padding-bottom: 28px;
    background-size: 100% 100%;

    img {
      max-height: 48px;
    }
  }

  &-title {
    padding: 17px 24px;
    background: #363636;
    color: #fff;
    width: 80%;
    text-align: left;
    transform: translateY(-50%);
  }

  &-button {
    margin-top: 42px;
    margin-right: 24px;
    border-radius: 24px;
    border: 1px solid #cdd2db;
    padding: 14px 33px;
    background: none;
    font-size: 14px;
    font-weight: 600;
    color: #4949e0;
    cursor: pointer;

    svg {
      margin-left: 25px;
    }
  }

  @media (min-width: 768px) {
    padding-right: 24px;
    margin-top: 78px;
    margin-bottom: 162px;

    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 245px);
      justify-content: center;
      grid-gap: 20px;
      width: 100%;
      grid-template-rows: 194px;
      overflow: unset;
    }

    &-item {
      margin-right: unset;
      min-width: unset;
    }

    &-button {
      margin-top: 68px;
      margin-right: 0;
    }
  }
}

.supported-chains {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  padding-top: 0;

  li {
    padding: 10px 20px;
    border-radius: 14px;
    background: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-inline: 12px;
    margin-block: 8px;

    img {
      height: 21px;
      margin-right: 9px;
    }

    @media (min-width: 768px) {
      margin-inline: 23px;
      margin-block: 14px;
      padding: 18px 44px;
    }
  }
}
</style>
