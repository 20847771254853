<template>
  <div>
    <transition name="fade">
      <div
        v-if="show"
        class="kript-modal-backdrop"
        :class="{ show: backdrop }"
        @click.prevent="dismissible ? close() : null"
      ></div>
    </transition>
    <transition :name="animation">
      <div
        :ref="`kript-modal-${id}`"
        :id="`kript-modal-${id}`"
        class="kript-modal"
        v-if="show"
        tabindex="-1"
        :aria-labelledby="`modal-${id}-title`"
      >
        <div class="header">
          <h3 class="title">{{ title }}</h3>
          <button class="icon" @click="close">
            <img src="@/assets/images/close.svg" alt="" />
          </button>
        </div>
        <slot>
          <div class="body">
            <p class="body-text">
              {{ body }}
            </p>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "KriptModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: String,
      default: "flip",
    },
  },
  data() {
    return {
      focusableElements: null,
    };
  },
  computed: {
    modal() {
      return document.getElementById(`modal-${this.name}`);
    },
    id() {
      return `kript-modal-${this.title?.replace(" ", "-")?.toLowerCase()}`;
    },
    firstFocusableEl() {
      return this.focusableElements?.[0];
    },
    lastFocusableEl() {
      return this.focusableElements?.[this.focusableElements.length - 1];
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    getFocusableElements() {
      this.focusableElements = this.$el?.querySelectorAll?.(
        'a, button:not(:disabled), input:not(:disabled), textarea:not(:disabled), select:not(:disabled), details, [tabindex]:not([tabindex="-1"])',
      );
    },
    handleFocus(event) {
      const TAB_CODE = 9;
      const ESC_CODE = 27;

      const handleForwardTab = () => {
        if (document.activeElement.isSameNode(this.lastFocusableEl)) {
          event.preventDefault();
          this.firstFocusableEl.focus();
        }
      };

      const handleBackwardTab = () => {
        if (document.activeElement.isSameNode(this.firstFocusableEl)) {
          event.preventDefault();
          this.lastFocusableEl.focus();
        }
      };

      switch (event.keyCode) {
        case TAB_CODE:
          if (
            !!this.focusableElements.length &&
            this.focusableElements.length === 1
          ) {
            event.preventDefault();
            break;
          }

          if (event.shiftKey) {
            handleBackwardTab();
          } else {
            handleForwardTab();
          }

          break;
        case ESC_CODE:
          this.close();
      }
    },
    focusOnFirstElement() {
      if (!this.focusableElements.length) {
        return;
      }

      this.$nextTick(() =>
        this.dismissible
          ? this.focusableElements?.[1]?.focus?.()
          : this.firstFocusableEl.focus(),
      );
    },
  },
  beforeUnmount() {
    if (this.show) {
      this.$emit("modal-hidden");
    }
    document.removeEventListener("keydown", this.handleFocus);
  },
  mounted() {
    this.$nextTick(function () {
      this.getFocusableElements();
      this.focusOnFirstElement();
    });
    document.addEventListener("keydown", this.handleFocus);
  },
};
</script>

<style lang="scss" scoped>
.kript-modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9998;

  &.show {
    background: #c4c4c4;
    backdrop-filter: blur(5px);
  }
}

.kript-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 80%;
  width: 90vw;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  /* padding: 36px; */
  z-index: 9999;
  background: #fff;
  box-shadow: 6px 0px 20px #00000029;
  border-radius: 12px;

  @media (min-width: 1200px) {
    width: unset;
    width: Min(768px, 90vw);
  }

  .header {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 13px;
    grid-gap: 15px;
    padding: 36px;

    .title {
      font-size: 16px;
      line-height: 140%;
      margin-inline: auto;
      width: fit-content;
    }

    button {
      all: unset;
      cursor: pointer;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .body {
    padding: 0 36px;

    .body-text {
      font-size: 14px;
      line-height: 140%;
    }
  }
}

.scale-enter-active {
  animation: scale 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.scale-leave-active {
  animation: scale 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) reverse;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.swirl-enter-active {
  animation: swirl 0.6s ease-out;
}

.swirl-leave-active {
  animation: swirl 0.6s ease-out reverse;
}
@keyframes swirl {
  0% {
    transform: translate(-50%, -50%) rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 1;
  }
}

.flip-enter-active {
  animation: flip 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.flip-leave-active {
  animation: flip 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse;
}

@keyframes flip {
  0% {
    transform: translate(-50%, -50%) rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

.swing-enter-active {
  animation: swing 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.swing-leave-active {
  animation: swing 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) reverse;
}

@keyframes swing {
  0% {
    transform: translate(-50%, -50%) rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

.slide-enter-active {
  animation: slide 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-leave-active {
  animation: slide 0.6s cubic-bezier(0.23, 1, 0.32, 1) reverse;
}

@keyframes slide {
  0% {
    transform: translate(-50%, -1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
