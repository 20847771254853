<template>
  <div class="home">
    <kript-container>
      <h1>API Infrastructure for your Crypto Business</h1>
      <p>
        We empower you to provide your users with simple and secure crypto-fiat
        experiences through our easy-to-use APIs.
      </p>
    </kript-container>
    <ul class="images">
      <li class="rectangle70">
        <img src="@/assets/images/Rectangle70.png"/>
      </li>
      <li class="rectangle69">
        <img src="@/assets/images/Rectangle69.png"/>
      </li>
      <li class="rectangle73">
        <img src="@/assets/images/Rectangle73.png"/>
      </li>
      <li class="rectangle68">
        <img src="@/assets/images/Rectangle68.png"/>
      </li>
      <li class="group422">
        <img src="@/assets/images/Group422.png"/>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  background: {
    image: url("~@/assets/images/backgrounds/white.jpg");
    size: cover;
    attachment: fixed;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 188px;
  min-height: 100vh;

  h1 {
    font-size: 38px;
  }

  p {
    margin-top: 30px;
    line-height: 32px;
  }

  span {
    margin-top: 33px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;

    img {
      height: 23.08px;
      margin-left: 10px;
    }
  }

  @media (min-width: 768px) {
    padding-block: unset;
    padding-top: 247px;
    padding-bottom: 148px;
    text-align: center;
    align-items: center;

    h1 {
      font-size: 80px;
    }

    p {
      margin-top: 24px;
      max-width: 666px;
      font-size: 18px;
    }

    span {
      margin-top: 68px;

      img {
        height: 23.08px;
        margin-left: 10px;
      }
    }
  }
}

.images {
  display: none;

  li {
    position: absolute;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .rectangle70 {
    top: 169px;
    left: 663px;
    width: 40px;
    height: 40px;
  }

  .rectangle69 {
    top: 189px;
    left: 1033px;
    width: 82px;
    height: 70px;
  }

  .rectangle73 {
    top: 211px;
    left: 351px;
    width: 53px;
    height: 72px;
  }

  .rectangle68 {
    top: 449px;
    left: 183px;
    width: 82px;
    height: 70px;
  }

  .group422 {
    top: 380px;
    left: 1034px;
    width: 149px;
    height: 125px;
    /* transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0); */
  }

  @media (min-width: 768px) {
    display: block;

    .rectangle70 {
      top: 169px;
      left: 663px;
      width: 40px;
      height: 40px;
    }

    .rectangle69 {
      top: 189px;
      left: 1033px;
      width: 82px;
      height: 70px;
    }

    .rectangle73 {
      top: 211px;
      left: 351px;
      width: 53px;
      height: 72px;
    }

    .rectangle68 {
      top: 449px;
      left: 183px;
      width: 82px;
      height: 70px;
    }

    .group422 {
      top: 380px;
      left: 1034px;
      width: 149px;
      height: 125px;
      /* transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0); */
    }
  }
}
</style>
