<template>
  <div class="home">
    <kript-container>
      <span class="pill">AND SO IT BEGINS</span>
      <h2>Let’s build together!</h2>
      <p>
        Built by a stellar team of blockchain experts. We’ve made building
        cryptocurrency payment solutions seamless and easy, reducing the burden
        of technical integration on your end.
      </p>
      <button class="btn">
        <a href="https://demo.kriptup.io" target="_blank" rel="noreferrer noopener">
          Try the sandbox
        </a>
      </button>
    </kript-container>
  </div>
</template>

<script>
export default {
  name: "HomeBuild",
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 75px;
  padding-bottom: 67px;

  .pill {
    margin-bottom: 13px;
  }

  h2 {
    font-size: 38px;
    font-weight: normal;
  }

  p {
    margin-top: 18px;
    line-height: 32px;
  }

  .btn {
    margin-top: 24px;
  }

  @media (min-width: 768px) {
    padding-top: 170px;
    padding-bottom: 131px;
    text-align: center;
    align-items: center;

    h2 {
      font-size: 48px;
    }

    p {
      margin-top: 24px;
      max-width: 666px;
    }
  }
}
</style>
