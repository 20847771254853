<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FluxContainer",
};
</script>

<style lang="scss" scoped>
$max-widths: (
  xs: 100%,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1320px,
);

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;

  @each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
      max-width: map-get($max-widths, $name);
    }
  }
}
</style>
