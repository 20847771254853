<template>
  <div ref="root" class="body">
    <div class="tools">
      <kript-container>
        <span class="pill">Tools</span>
        <div class="grid">
          <h2>All you need to build your dream</h2>
          <div class="large-pill">
            <img src="@/assets/images/star.svg" alt="" />
            <p>Off-ramp and On-ramp Liquidity.</p>
          </div>
        </div>
      </kript-container>
      <div class="video-wrapper">
        <video loop autoplay muted src="@/assets/video/krypt.mp4"></video>
      </div>
    </div>
    <div class="access grid">
      <kript-container>
        <div class="video-wrapper">
          <video loop autoplay muted src="@/assets/video/fun-cubes.mp4"></video>
        </div>
        <div class="text">
          <span class="pill">Access</span>
          <h2>Liquidity for off-ramp and on-ramp</h2>
          <p>
            We put the power in your hands with easy-to-use APIs that you can
            use to mesmerize your users with simple and secure crypto-fiat
            experiences.
          </p>
        </div>
      </kript-container>
    </div>
    <div class="secure grid">
      <kript-container>
        <div class="video-wrapper">
          <video loop autoplay muted src="@/assets/video/plane.mp4"></video>
        </div>
        <div class="text">
          <span class="pill">Secure</span>
          <h2>Issue secure crypto wallets to your users</h2>
          <p>
            Generate secure crypto wallets for users, and track balances and
            transactions for each wallet. Users can buy, sell, send and receive
            cryptocurrencies using their unique wallets.
          </p>
        </div>
      </kript-container>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: "HomeBody",
  setup() {
    const root = ref(null);

    onMounted(() => {
      const videos = root?.value?.querySelectorAll?.("video");
      for (const video of videos) video.play();
    });

    return {
      root,
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding-block: 62px;
  @media (min-width: 768px) {
    padding-block: 75px;
  }

  .pill {
    margin-bottom: 13px;
  }

  .tools {
    h2 {
      font-size: 32px;
    }

    .large-pill {
      padding: 28px 0;
      display: flex;
      align-items: center;

      img {
        height: 58px;
        margin-right: 24px;
      }

      p {
        font-size: 20px;
      }
    }

    .video-wrapper {
      /* width: 100vw; */
      mask: {
        image: url(~@/assets/masks/tools-mask.png);
        size: cover;
        position: center;
      }

      video {
        width: 100%;
        min-height: 268px;
        aspect-ratio: 715.74 / 267.3;
        object-fit: cover;
      }
    }

    @media (min-width: 768px) {
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 34px;
        align-items: center;
      }

      h2 {
        font-size: 60px;
      }

      .large-pill {
        padding: 33px 60px;
        border-radius: 85px;
        display: flex;
        align-items: center;
        background-image: url(~@/assets/images/backgrounds/purple.jpg);
        background-size: 100%;

        img {
          height: 58px;
          margin-right: 24px;
        }

        p {
          font-size: 40px;
          /* line-height: 51px; */
        }
      }

      .video-wrapper {
        margin-top: 120px;
        mask-position: left;

        video {
          aspect-ratio: 1451 / 542;
        }
      }
    }
  }

  .grid {
    .container {
      display: grid;
      grid-gap: 48px;
      align-items: center;

      .video-wrapper {
        width: 100%;
        video {
          width: 100%;
          object-fit: cover;
        }
      }

      .text {
        h2 {
          font-size: 28px;
          font-weight: normal;
        }

        p {
          margin-top: 18px;
        }
      }

      @media (min-width: 768px) {
        grid-template-columns: 40% 1fr;
        grid-gap: 102px;

        .text {
          h2 {
            font-size: 48px;
          }

          p {
            margin-top: 24px;
          }
        }
      }
    }

    &.access {
      margin-top: 70px;

      .video-wrapper {
        order: 2;
        width: 100%;

        mask: {
          image: url(~@/assets/masks/access-mask.png);
          size: cover;
          position: center;
        }
      }

      .text {
        order: 1;
      }
    }

    &.secure {
      margin-top: 70px;

      .video-wrapper {
        order: 2;
        width: 100%;

        mask: {
          image: url(~@/assets/masks/secure-mask.png);
          size: cover;
          position: center;
        }

        video {
          width: 100%;
          object-fit: cover;
        }
      }

      .text {
        order: 1;
      }

      @media (min-width: 768px) {
        margin-top: 40px;

        .video-wrapper {
          order: 1;
        }

        .text {
          order: 2;
        }
      }
    }
  }
}
</style>
